<div class="layout-footer">
    <div  class="logo-text" >
      <div style="cursor: pointer;" (click)="radportalLink()">
        <img
        src="assets/layout/images/radportal-logo-mini.png"
        alt="mirage-layout"
      />
      </div>
  
      <div class="text">
        <div style="cursor: pointer;" (click)="radportalLink()">
          <h1>RadPortal</h1>
        </div>
        
        <span>Version: 1.19.0</span>
      </div>
    </div>
    <div class="icons">
      <!-- <div class="icon icon-hastag">
                  <i class="pi pi-home"></i>
              </div>
              <div class="icon icon-twitter">
                  <i class="pi pi-globe"></i>
              </div>
              <div class="icon icon-prime">
                  <i class="pi pi-bookmark"></i>
              </div> -->
    </div>
  </div>