import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeLimitService {

  private createdAt: Date = new Date('2024-07-27T00:00:00');

  constructor() {}

  isWithinTimeLimit(hours: number, fromHour: Date): boolean {
    const finalizedOn = new Date(fromHour);
    // console.log("finalizedOn: ", finalizedOn);
    const now = new Date();
    const timeDiff = Math.abs(now.getTime() - finalizedOn.getTime());
    const hoursDiff = Math.ceil(timeDiff / (1000 * 3600));
    return hoursDiff <= hours;
  }
}
