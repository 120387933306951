import { ExamResultHistoryResModel } from "./exam/exam-result-history-res.model";
import { ExamResultNoteHistoryResModel } from "./exam/exam-result-note-history-res.model";
import { ExamResult } from "./exam/exam-result.model";
export class StudyModel {
  Provider?:string;
  Hn: string;
  OrderSubmitId: number;
  RegId: number;
  Fname: string;
  Lname: string;
  Mname: string;
  FnameEng: string;
  MnameEng: string;
  LnameEng: string;
  PatientNameEng: string;
  PatientNameThai: string;
  HospitalName: string;
  SendStatus: string;
  FinalizedBy: string;
  FinalizedById?: number;
  Dob: Date;
  DobString: string;
  Addr1: string;
  OrderId: number;
  AccessionNo: string;
  PacsAccessionNo: string;
  ExamDt: Date;
  ExamId: number;
  ExamName: string;
  ExamCode: string;
  ExamUid: string;
  Status: string;
  StatusName: string;
  ModalityId: number;
  ModalityName: string;
  OrderDt: Date;
  OrderDtString: string;
  StudyDate: Date;
  OrderTimeStr: string;
  PatientName: string;
  Gender: string;
  PatientType: string;
  NoOfImage: number;
  IsCloud: boolean;
  CancelHost: string;
  CancelledBy: number;
  CancelledReason: string;
  CancelledOn: Date;
  OrgId: number;
  OrgParentId: number;
  OrgName: string;
  StudyInstanceUId: string;
  AbnormalityScore: number;
  IsDisagree: boolean;
  FeedbackComment: string;
  ExamResult: ExamResult;
  ExamResultHistory: ExamResultHistoryResModel;
  Examresultnotehistory: ExamResultNoteHistoryResModel[];
  Queue: number;
  BusId: number;
  SSN: string;
  BusName: string;
  MwlAccess: boolean;
  FilePath: string;
  PacsSendStatus: string;
  Age: number;
  FinalizedStatusName: string;
  TemplateId?: number;
  Color: string;
  TemplateName: string;
  LocalPacsUrl: string;
  DCMViewer: string;
  RepFooter1: string;
  IsCompared: boolean;
  TechnicalQuality: string;
  LockedByName: string;
  CategoryDisplayText: string;
  //LockedOn: string;
  LockedOn: Date;
  ComparedText: string;
  ShareImageUrl: string;
  FinalizedOn?: Date;
  FinalizedTimeStr: string;
  Tuberculosis: number;
  PulmonaryEdema: number;
  LungOpacity: number;
  Mass: number;
  AtelectasisScore: number;
  CalcificationScore: number;
  CardiomegalyScore: number;
  ConsolidationScore: number;
  FibrosisScore: number;
  MediastinalWideningScore: number;
  NoduleScore: number;
  PleuralEffusionScore: number;
  PneumoperitoneumScore: number;
  PneumothoraxScore: number;
  TbScore: number;
  CaseNo: string;
  StudyStandard: string;
  Room: number;
  PrintCount: number;
  UserId?: number;
  DocumentCount?: number;
  AssignTo?: string;
  AssignedTo?: number;
  MultipleRadiologisAssignedTo: number[];
  Assignedby?: number;
  AssignedToName?: string;
  AssignedbyName?: string;
  AssignedOn: Date;
  GovtId: string;
  GovtName: string;
  GovtExamUID: string;
  IsDraft: boolean;
  ServiceType: number;
  ServiceTypeId: number;
  ServiceTypeUID: string;
  ServiceTypeText: string;
  AddendumPosition: string;
  AddendumCount: number;
  ClinicalSignificanceId: number;
  ClinicalSignificanceText: string;
  ClinicalSignificanceUid: string;
  LastModifiedBy: number;
  TatsetupId: number;
  TatsetupUid: string;
  TatsetupName: string;
  ColorCode: string;
  HistoryCount: number;
  CreatedFrom: string;
  IsCritical?: boolean;
  SeverityId: number;
  IsReset?: boolean = true;
  VersionNo?: number;
  ResetBy?: number;
  ResetByName: string;
  ResetOn?: Date;
  DisplayStudyVersion?: boolean;
  StudyVersionCustomText: string;
  ResetTo: string;
  ResetFrom: string;
  ResetreasonId?: number;
  IsLoadLastVersionReport: boolean;
  ExamType?: number;
  GovtExamType?: number;
  ExamimagequalityId: number;
  IsCustomtext?: boolean;
  ExamimagequalityCustomtext: string;
  IsReviewed?: boolean;
  ReviewedOn?: Date;
  OverallRating?: number;
  SelectdFromDate?: string;
  SelectdToDate?: string;
  InsuranceType: string;

  PacsIssent: boolean;
  PacsSentOn?: Date;
  RisIssent: boolean;
  RisSentOn?: Date;
  LocalPacsIssent: boolean;
  LocalPacsSentOn?: Date;

  GOVAPIIsSent?: boolean;
  GOVAPISentBy: number;
  GOVAPISentOn?: Date;
  GOVAPIResponseCode?: number;
  GOVAPIResponseMessage: string;

  RisSendStatusText:string;
  TitleEng:string;
  Title:string;
  PacsSendStatusText:string;
  LocalPacsSendStatusText:string;
  ActualEarning:string;
  Hcode: string;
  Hospmain: string;
  HospSub: string;
  GovCode: string;
  GovName: string;
  PermitNo: string;
  HType: string;
  HTypeId: number;
  GovExamRate: number;
  MultipleReportingallow?: boolean;
  MultipleNoOfReporting?: number;
  FinalizedVersion?: number;
  DraftVersion?: number;
  FinalizedByAllRadName: string;
  FinalizedByName: string;
  ImageAddedCount?: number;
  ReportsubmitTogov?: boolean;
  ReportPdfPath: string;
  ReportImagePath: string;
  ReportDicomPath: string;
  DicomImagePath: string;
  DicomImageChecked: boolean = true;
  StudyImageInfos: StudyImageInfos[] = [];
  aiRequestlogList: AiRequestlogModal[] = [];
  OpenAiDefaultPrompt: string;
  ChangeLogDate: Date;
  ChangedByName: string;
  OrderAssignList: RisOrderAssigned[];

  DraftBy?: number;
  DraftByName: string;
  DateTime?: Date;
  DraftReason: string;

  DraftCancelledBy?: number;
  DraftCancelledByName: string;
  DraftCancelledOn?: Date;
  DraftCancelledReason: string;

  ScheduleStatus: string;
  ScheduleStatusCode: string;
  ScheduleStatusWithCode: string;
  ScheduleCreatedOn?: Date
  ScheduleTryCount?: number;
  FrsStatus: string;
  FrsStatusCode: string;
  FrsStatusWithCode: string;
  FrsSentOn?: Date;
  FrsSentCount?: number;
  FinalizedReportSubmitted?: boolean;
}

export class StudyImageInfos
{
  Id: number;
  imageUrl: string;
  imageName: string;
  isSelected: boolean;
}

export class AiRequestlogModal {
  RequestlogId: number;
  AiModel: string;
  AiPrompt: string;
  ReportText: string;
  AiReportresponse: string;
  NoOfImage: number;
  OrderId: number;
  ExamId: number;
  AccessionNo: string;
  SerialNo: number;
  OrgId: number;
}

export class RisOrderAssigned {
  OrderAssignedId: number;
  OrderId: number;
  AccessionNo: string;
  AssignedTo?: number;
  AssignedBy?: number;
  AssignedOn?: string;
  CreatedBy?: number;
  CreatedOn?: string;
  LastModifiedBy?: number;
  LastModifiedOn?: string;
  IsFinalized: boolean;
}