export class DraftViewModel {
    OrderId: number;
    AccessionNo: string;
    DraftBy?: number;
    DraftReason: string;
    DraftCancelledBy?: number;
    DraftCancelledReason: string;
    OrgId?: number;
    IsDraftReportClear: boolean;
    ResultTextHtml: string;
    ResetByName: string;
}