import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertifyService } from '../_services/common/alertify.service';
import { EncrypDepcrypService } from '../_services';
import { environment } from 'src/environments/environment';


@Injectable()
export class EncryptionInterceptor implements HttpInterceptor {

  constructor(
    private encryptionService: EncrypDepcrypService, 
    private _alertifyService: AlertifyService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(environment.isCloud && !request.url.includes("Documents/") && !request.url.includes("assets/")) {
      var urls = request.url.split('api/');
      const encryptedUrl = this.encryptionService.encrypt(urls[1]);
      const clonedRequest = request.clone({
        url: `${urls[0]}api/${encryptedUrl}`,
      });
  
      return next.handle(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500) {
            this._alertifyService.error('500 Internal Server Error');
          } else if(error.status === 415) {
            this._alertifyService.error("Unsupported Media Type");
          } else {
            this._alertifyService.error('An HTTP error occurred');
          }
          return throwError(() => error);
        }),
        finalize(() => {
          // console.log('Finalized');
        })
      );
    } else {
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 500) {
            this._alertifyService.error('500 Internal Server Error');
          } else if(error.status === 415) {
            this._alertifyService.error("Unsupported Media Type");
          } 
          
          // else {
          //   this._alertifyService.error('An HTTP error occurred');
          // }
          return throwError(() => error);
        }),
        finalize(() => {
          // console.log('Finalized');
        })
      );
    }
    
  }
}
