export class RisOrderreceivedapilogModel {
    OrderreceivedapilogId: number;
    OrderId?: number;
    AccessionNo: string;
    ApiName: string;
    ApiUrl: string;
    RequestBody: string;
    ResponseBody: string;
    RequestUserid?: number;
    ResponseStatus?: number;
    ResponseMessagee: string;
    APIType: string;
    RequestedOn?: Date;
    RespondedOn?: Date;
    OrgId?: number;
    CreatedBy?: number;
    CreatedOn?: Date;
    LastModifiedBy?: number;
    LastModifiedOn?: Date;
    RequestUserName?: string;
}