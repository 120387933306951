import { CustomerListModel } from "./customer-list/customer-list.model";
import { ExamDtoModel } from "./exam/exam-dto.model";
import { ExamImageQualityModel } from "./exam/exam-image-quality.model";
import { ExamResultHistoryResModel } from "./exam/exam-result-history-res.model";
import { ExamResultNoteHistoryResModel } from "./exam/exam-result-note-history-res.model";
import { ExamResultNote } from "./exam/exam-result-note.model";
import { ExamResultSeverityModel } from "./exam/exam-result-severity.model";
import { RisExamTypeModel } from "./exam/ris-exam-type.model";
import { GblEnvResModel } from "./gbl-env/gbl-env-res.model";
import { GeneralResModel } from "./general/general-res.model";
import { ResendToGovTypeModel } from "./integration-monitoring-center/resend-to-gov-type.model";
import { ResendToGovViewModel } from "./integration-monitoring-center/resend-to-gov-view.model";
import { SentStudyListModel } from "./integration-monitoring-center/sent-study-list.model";
import { JasperReportDetailsModel } from "./jasper-report/jasper-report-details.model";
import { AiListModel } from "./open-ai/ai-list.model";
import { OpenAiModel } from "./open-ai/open-ai.model";
import { OrderclinicalsignificanceModel } from "./order-clinical-significance/order-clinical-significance.model";
import { DocumentHistoryResModel } from "./order-document/document-history-res.model";
import { OrderDocumentDownloadResModel } from "./order-document/order-document-download-res.model";
import { OrderDocumentModel } from "./order-document/order-document-model";
import { OrderDocumentTypeResModel } from "./order-document/order-document-type-res.model";
import { OrderScoreModel } from "./order-score/order-score.model";
import { OrderStudyDeclineReqModel } from "./order-study-decline/order-study-decline-req.model";
import { RadProfileStudyCountChartModel } from "./radiologist-profile/rad-profile-study-count-chart.model";
import { RatingViewModel } from "./rating/rating-view.model";
import { HisRegistrationModel } from "./registration/his-registration.model";
import { GblReportHeaderModel } from "./report/gbl-report-header-setting.model";
import { GblReportJobtypeSettingModel } from "./report/gbl-report-jobtype-setting.model";
import { GblReportParameterSettingModel } from "./report/gbl-report-parameter-setting.model";
import { GblReportParameterModel } from "./report/gbl-report-parameter.model";
import { GblReportViewModel } from "./report/gbl-report-view.model";
import { GblReportModel } from "./report/gbl-report.model";
import { ReportFilterViewModel } from "./report/report-filter-view.model";
import { ResetStudyReqModel } from "./reset-study/reset-study-req.model";
import { StudyResetReasonResModel } from "./reset-study/study-reset-reason-res.model";
import { StudyVersionResModel } from "./reset-study/study-version-res.model";
import { DraftViewModel } from "./ris-order/draft-view.model";
import { OrgResponseViewModel } from "./ris-order/org-response-view.model";
import { RisExamResultKeyImagesViewModel } from "./ris-order/ris-examresult-key-images-view.model";
import { RisExamResultKeyImagesModel } from "./ris-order/ris-examresult-key-images.model";
import { RisModalityModel } from "./ris-order/ris-modality.model";
import { RisOrderDtlModel } from "./ris-order/ris-order-dtl.model";
import { RisOrderreceivedapilogModel } from "./ris-order/ris-order-received-api-log.model";
import { RisOrderSubmitDTLAPISendLogViewModel } from "./ris-order/ris-order-submit-dtl-api-send-log-view.model";
import { RisOrderModel } from "./ris-order/ris-order.model";
import { ServiceTypeModel } from "./service-type/service-type.model";
import { ChangeOrderStatusReqModel } from "./study/change-order-status-req.model";
import { StudyReportingTemplateDropdownResModel } from "./study/study-reporting-template-dropdown-res.model";
import { StudyReportingTemplateModel } from "./study/study-reporting-template.model";
import { StudyTrackingModel } from "./study/study-tracking.model";
import { UpdateStudyReqModel } from "./study/update-study-req.model";
import { HrEmpProfileModel } from "./user-models/hr-emp-profile.model";
import { HrEmpStudyChartModel } from "./user-models/hr-emp-study-chart.model";
import { HrEmpSubspecialtyModel } from "./user-models/hr-emp-subspecialty.model";
import { HrEmpModel } from "./user-models/hr-emp.model";
import { HrJobtitleModel } from "./user-models/hr-jobtitle.model";
import { UserPreservedDataModel } from "./user-models/user-preserved-data.model";

export const models: any[] = [
  RisOrderreceivedapilogModel,
  DraftViewModel,
  AiListModel,
  OpenAiModel,
  RisExamResultKeyImagesModel,
  RisExamResultKeyImagesViewModel,
  JasperReportDetailsModel,
  GblReportHeaderModel,
  GeneralResModel,
  OrderDocumentModel,
  OrderDocumentTypeResModel,
  OrderDocumentDownloadResModel,
  CustomerListModel,
  OrderStudyDeclineReqModel,
  ExamResultNote,
  DocumentHistoryResModel,
  RatingViewModel,
  ServiceTypeModel,
  UpdateStudyReqModel,
  GblEnvResModel,
  OrderScoreModel,
  OrderclinicalsignificanceModel,
  StudyTrackingModel,
  UserPreservedDataModel,
  ExamResultSeverityModel,
  ChangeOrderStatusReqModel,
  ResetStudyReqModel,
  StudyVersionResModel,
  ExamResultHistoryResModel,
  ExamResultNoteHistoryResModel,
  StudyResetReasonResModel,
  StudyReportingTemplateModel,
  StudyReportingTemplateDropdownResModel,
  RisExamTypeModel,
  ExamImageQualityModel,
  ExamDtoModel,
  GblReportModel,
  GblReportParameterSettingModel,
  GblReportJobtypeSettingModel,
  GblReportParameterModel,
  GblReportViewModel,
  ReportFilterViewModel,
  HrEmpModel,
  HrEmpProfileModel,
  HrEmpSubspecialtyModel,
  HrJobtitleModel,
  RisOrderModel,
  RisOrderDtlModel,
  RisModalityModel,
  HisRegistrationModel,
  HrEmpStudyChartModel,
  RadProfileStudyCountChartModel,
  RisOrderSubmitDTLAPISendLogViewModel,
  OrgResponseViewModel,
  ResendToGovViewModel,
  ResendToGovTypeModel,
  SentStudyListModel,
];

export * from "./ris-order/ris-order-received-api-log.model";
export * from "./ris-order/draft-view.model";
export * from "./open-ai/ai-list.model";
export * from "./open-ai/open-ai.model";
export * from "./ris-order/ris-examresult-key-images.model";
export * from "./ris-order/ris-examresult-key-images-view.model";
export * from "./jasper-report/jasper-report-details.model";
export * from "./report/gbl-report-header-setting.model";
export * from "./customer-list/customer-list.model";
export * from "./exam/exam-dto.model";
export * from "./exam/exam-image-quality.model";
export * from "./exam/exam-result-history-res.model";
export * from "./exam/exam-result-note-history-res.model";
export * from "./exam/exam-result-note.model";
export * from "./exam/exam-result-severity.model";
export * from "./exam/ris-exam-type.model";
export * from "./gbl-env/gbl-env-res.model";
export * from "./general/general-res.model";
export * from "./integration-monitoring-center/resend-to-gov-type.model";
export * from "./integration-monitoring-center/resend-to-gov-view.model";
export * from "./integration-monitoring-center/sent-study-list.model";
export * from "./order-clinical-significance/order-clinical-significance.model";
export * from "./order-document/document-history-res.model";
export * from "./order-document/order-document-download-res.model";
export * from "./order-document/order-document-model";
export * from "./order-document/order-document-type-res.model";
export * from "./order-score/order-score.model";
export * from "./order-study-decline/order-study-decline-req.model";
export * from "./radiologist-profile/rad-profile-study-count-chart.model";
export * from "./rating/rating-view.model";
export * from "./registration/his-registration.model";
export * from "./report/gbl-report-jobtype-setting.model";
export * from "./report/gbl-report-parameter-setting.model";
export * from "./report/gbl-report-parameter.model";
export * from "./report/gbl-report-view.model";
export * from "./report/gbl-report.model";
export * from "./report/report-filter-view.model";
export * from "./reset-study/reset-study-req.model";
export * from "./reset-study/study-reset-reason-res.model";
export * from "./reset-study/study-version-res.model";
export * from "./ris-order/org-response-view.model";
export * from "./ris-order/ris-modality.model";
export * from "./ris-order/ris-order-dtl.model";
export * from "./ris-order/ris-order-submit-dtl-api-send-log-view.model";
export * from "./ris-order/ris-order.model";
export * from "./service-type/service-type.model";
export * from "./study/change-order-status-req.model";
export * from "./study/study-reporting-template-dropdown-res.model";
export * from "./study/study-reporting-template.model";
export * from "./study/study-tracking.model";
export * from "./study/update-study-req.model";
export * from "./user-models/hr-emp-profile.model";
export * from "./user-models/hr-emp-study-chart.model";
export * from "./user-models/hr-emp-subspecialty.model";
export * from "./user-models/hr-emp.model";
export * from "./user-models/hr-jobtitle.model";
export * from "./user-models/user-preserved-data.model";
